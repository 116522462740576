.home {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center; 
}

.home-content {
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif;
  align-items: center; 
}

.photogrid {
  box-sizing: border-box;
  width: 70%;
  padding: 10px;
  /* margin-right: 20px; */
  align-items: center; 
  /* height: 100%; */
}

.photogrid img{
  width: 100%;
  /* box-shadow: 0 0 10px 1px #009bff14; */

}

.home-content {
  width: 30%;
  padding: 10px;
}
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
  animation-delay: 1s;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* .carosel:before {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgba(0,0,0,.5);
  } */


@media only screen and (min-width: 550px) and (max-width: 850px) {
  .home {
    flex-direction: column-reverse;
  }
  .photogrid {
    width: 100%;
    margin:auto;
    text-align: center;
  }
  .photogrid img{
    width:60%;

  }
  
  .home-content {
    width: 100%;

  }
}

@media only screen and (max-width: 550px) {
  .home {
    flex-direction: column-reverse;
  }
  .photogrid {
    width: 100%;
    margin:auto;
    text-align: center;
  }
  .home-content {
    width: 100%;

  }
  .photogrid img{
    width:100%;
  }

}