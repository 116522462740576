.image-card {
  width: 50%;
  height: 20vw;
  object-fit: cover;
}

#lightbox-img {
  height: 80vh;
  max-width: 90vw;
  object-fit: cover;
}

#lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
}

#images {
  text-align: center;
}

#lightboxX {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 40px;
  color: white;
}

#lightboxX:hover {
  cursor: pointer;
  color: #69c2ff;
}

.arrows {
  position: relative;
  font-size: 50px;
  color: white;
}

#arrowR{
  margin-right: 10px;
}

#arrowL{
  margin-left: 10px;
}

.arrows:hover {
  cursor: pointer;
  color: #69c2ff;
}

/*Completely optional styling that has nothing to do with the lightbox*/
/* button {
    color: white;
    border: 2px solid #a167da;
    background-color: #a167da;
    font-size: 1.2rem;
  }
  
  a {
    color: #7a4baa;
  }
  
  h1 {
    color: #7a4baa;
  }
  
  p {
    margin-bottom: 1.5rem;
    font-size: .9rem;
  } */

img:hover,
button:hover {
  cursor: pointer;
}
