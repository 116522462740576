#logo {
  height: 200px;
  width: 200px;
  position: relative;
  top: 0px;
  transition: all 0.3s;
}

#logoMobile {
  height: 120px;
  position: relative;
  top: -15px;
  transition: all 0.3s;
  display:none;
  /* text-align: center; */
}

header {
  background: white;
  /* padding-top: 10px; */
  height: 100px;
  text-align: left;
  position: fixed;
  width: 100%;
  top: 0;
  transition: height 0.3s;
  z-index: 1;
}


header.smaller {
  height: 50px;
}

header.smaller ul li .regLink {
  line-height: 38px;
}

header.smaller #logo {
  height: 100px;
  width: 100px;
}

#navbar ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5px;
  display: flex;
}

#navbar li {
  list-style-type: none;
  padding-top: 0;
  margin-top: 0;
  display: inline-block;
  max-height: 100px;
}

#navbar ul li:first-child {
  margin-right: auto;
  padding-left: 30px;
}

header ul li .regLink {
  /* font-family: 'Shrikhand', cursive; */
  /* font-family: 'Barlow Condensed', sans-serif; */
  font-family: 'Oswald', sans-serif;
  line-height: 85px;
  color: #69c2ff;
  padding: 30px;
  font-size: 24px;
  text-decoration: none;
  font-weight: 600;
  transition: ease 0.3s;
}

#navbar ul li .regLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

#navbarMobile ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5px;
  display: flex;
}

#navbarMobile li {
  list-style-type: none;
  padding-top: 0;
  margin-top: 0;
  display: inline-block;
  max-height: 100px;
}

#navbarMobile ul li:first-child {
  margin-right: auto;
  padding-left: 30px;
}



#navbarMobile ul li .regLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

#navbarMobile{
  display:none;
}

.bm-burger-button {
  display: none;
}

#mobileLogo{
  height: 100px;
}

@media only screen and (max-width: 950px) {
  header {
    height: 50px;
  }
  
  header ul li .regLink {
    line-height: 38px;
  }
  
  header #logo {
    height: 100px;
    width: 100px;
  }
  
  }


@media only screen and (max-width: 750px) {
.bm-burger-button {
    display: inline-block;
  }
  #logo {
    height: 100px;
    width: 100px;
    display: none;
  }
  header{
    height: 100px;
    
  }
  .regLink{
    display: none;
  }
  #logoMobile{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #navbarMobile ul li:first-child {
    margin: 0;
    padding: 0;
  }
  #nav{
    justify-content: center;
  }

  #navbar{
    display: none;
  }

  #navbarMobile{
    display: block;
  }

}
