.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background:#69c2ff;
  transition: 0.5s color;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
  transition: 0.5s color;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  transition: color 0.2s;
}

/* This positions the cross centrally in the button */
.bm-cross-button > span {
  font-family: 'Oswald', sans-serif;
  top: 2px !important;
  left: 2px !important;
}

/* This targets the actual elements that make up the cross */
.bm-cross {
  font-family: 'Oswald', sans-serif;
  background: #69c2ff;
  width: 8px !important;
  height: 32px !important;
  transition: color 0.2s;
}

.bm-cross-button:hover  .bm-cross {
  /* background: white; */
  border-bottom: 2px solid #69c2ff;
  /* text-decoration: underline; */
}



/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 90% !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  z-index: 10;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #69c2ff;
  margin-bottom: 30px;
  /* font-family: 'Shrikhand', cursive; */
  font-family: 'Oswald', sans-serif;
  font-size: 48px;
  text-decoration: none;
  transition: color 0.2s;
  outline: none !important;
}

.bm-item:hover {
  text-decoration: underline;
}

.sideLink{
  text-align: center;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 10;
}
/* This positions the cross centrally in the button */

/* 
#navbar {
  height: 100px;
  background-color: black;
  padding-top: 0;
  position: fixed;
  width: 100%;
  top: 0;
} */

/* .sidenav {
  height: 100%;
  width: 240px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #909596;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav a {
  font-family: 'Cairo', sans-serif;
  padding: 0px 8px 0px 26px;
  text-decoration: none;
  font-size: 25px;
  color: white;
  display: block;
  transition: 0.5s color;
}

.sidenav a:hover {
  color: #808080;
} */

#profPic {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-position: 48% 25%;
  margin-bottom: 20px;
}


