#education {
  margin: 0px 20px;
  font-family: 'Cairo', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

#education hr {
  background-color: #69c2ff;
  padding: 1px;
  border: none;
}

#eduImage {
  box-sizing: border-box;
  text-align: center;
}

#eduImage img {
  max-width: 400px;
  max-height: 400px;
}

#experience {
  margin: 0px 20px;
  font-family: 'Cairo', sans-serif;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#experience hr {
  background-color: black;
  padding: 1px;
  border: none;
}

.highlight {
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  font-size: 24px;
  text-decoration: underline;
  margin-bottom: 0;
}

.tabContent {
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
}

#skillset {
  box-sizing: border-box;
}

#courses {
  width: 100%;
}

/* Bar Graph Horizontal */
.bar-graph .year {
  -webkit-animation: fade-in-text 2.2s 0.1s forwards;
  -moz-animation: fade-in-text 2.2s 0.1s forwards;
  animation: fade-in-text 2.2s 0.1s forwards;
  opacity: 0;
}

.bar-graph-horizontal {
  z-index: -1;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  /* margin-left: 20px; */
}

.bar-graph-horizontal > div {
  float: left;
  margin-bottom: 8px;
  width: 100%;
}

.bar-graph-horizontal .year {
  font-family: 'Cairo', sans-serif;
  float: left;
  /* margin-top: 8px; */
  width: 20%;
}

.bar-graph-horizontal .bar {
  border-radius: 3px;
  height: 35px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
}

.bar-graph-one .bar::after {
  -webkit-animation: fade-in-text 2.2s 0.1s forwards;
  -moz-animation: fade-in-text 2.2s 0.1s forwards;
  animation: fade-in-text 2.2s 0.1s forwards;
  color: #fff;
  content: attr(data-percentage);
  font-weight: 700;
  position: absolute;
  right: 12px;
  top: 1px;
  font-family: 'Cairo', sans-serif;
}

.bar-graph-one .bar-zero .bar {
  background: #007bd0;
  -webkit-animation: show-bar-zero 1.2s 0.1s forwards;
  -moz-animation: show-bar-zero 1.2s 0.1s forwards;
  animation: show-bar-zero 1.2s 0.1s forwards;
}

.bar-graph-one .bar-one .bar {
  background: #409fe2;
  -webkit-animation: show-bar-one 1.2s 0.1s forwards;
  -moz-animation: show-bar-one 1.2s 0.1s forwards;
  animation: show-bar-one 1.2s 0.1s forwards;
}

.bar-graph-one .bar-two .bar {
  background-color: #1da3ff;
  -webkit-animation: show-bar-two 1.2s 0.2s forwards;
  -moz-animation: show-bar-two 1.2s 0.2s forwards;
  animation: show-bar-two 1.2s 0.2s forwards;
}

.bar-graph-one .bar-three .bar {
  background-color: #69c2ff;
  -webkit-animation: show-bar-three 1.2s 0.3s forwards;
  -moz-animation: show-bar-three 1.2s 0.3s forwards;
  animation: show-bar-three 1.2s 0.3s forwards;
}

.bar-graph-one .bar-four .bar {
  background-color: #9cd7ff;
  -webkit-animation: show-bar-four 1.2s 0.4s forwards;
  -moz-animation: show-bar-four 1.2s 0.4s forwards;
  animation: show-bar-four 1.2s 0.4s forwards;
}

/* Bar Graph Horizontal Animations */
@keyframes show-bar-zero {
  0% {
    width: 0;
  }
  100% {
    width: 16%;
  }
}

@keyframes show-bar-one {
  0% {
    width: 0;
  }
  100% {
    width: 32%;
  }
}

@keyframes show-bar-two {
  0% {
    width: 0;
  }
  100% {
    width: 48%;
  }
}

@keyframes show-bar-three {
  0% {
    width: 0;
  }
  100% {
    width: 64%;
  }
}

@keyframes show-bar-four {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}

@keyframes fade-in-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#courses {
  box-sizing: border-box;
  padding: 15px;
}

#courseList {
  align-items: center;
  justify-content: center;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: 0;
}

#courseList li {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 1em;
  box-sizing: border-box;
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  width: 290px;
  font-weight: 600;
  color: black;
  font-family: 'Cairo', sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

#courseList li:hover{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

#coursesHeader {
  font-family: 'Oswald', sans-serif;
  margin-top: 0;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}

#coursesHeader2 {
  font-family: 'Oswald', sans-serif;
  margin-top: 0;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}

.grid-container {
  align-items: center;
  justify-content: center;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: 0;
}
.grid-item {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 1em;
  box-sizing: border-box;
  margin: 15px;
  padding: 10px 15px;
  font-size: 18px;
  width: 320px;
  font-weight: 400;
  color: black;
  font-family: 'Cairo', sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.grid-item:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.grid-item hr {
  border: 1px dashed #69c2ff;
}

#eduHeader {
  font-family: 'Oswald', sans-serif;
  margin-top: 0;
  text-align: center;
  font-size: 48px;
  font-weight: 500;
}

.grid-item span {
  font-weight: 900;
}

.resumeCard{
  background-color: white;
  border-radius: 1em;
  box-sizing: border-box;
  padding: 2px 12px;
  margin: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.resumeCard:hover{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.experience-flex{
  display: flex;
  flex-direction: row;
}

.experience-left{
  width: 50%;
}

.experience-right{
  width: 50%;
}

.experience-flex p{
  margin-top: 2px;
}

@media only screen and (max-width: 550px) {
  #eduImage img{
    max-width: 375px;
  }
}

@media only screen and (max-width: 400px) {
  .highlight{
    font-size: 20px;
    max-width: 150px;
  }
}

@media only screen and (max-width: 1024px) {
  .bar-graph-horizontal{
    padding: 25px;
  }
}