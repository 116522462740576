.pu-content-container2 {
    font-family: 'Cairo', sans-serif;
    padding: 20px 30px;
  }
  #name2,
  #email2 {
    width: 100%;
    padding: 0.6em;
    margin-top: 5px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border: 1px solid black;
    font-family: 'Cairo', sans-serif;
    background: 0 0;
    color: black;
  }
  
  #message2 {
    max-width: 100%;
    width: 100%;
    min-height: 100px;
    padding: 0.4em;
    margin-top: 5px;
    margin-bottom: 20px;
    box-sizing: border-box;
    font-family: 'Cairo', sans-serif;
    border: 1px solid black;
    background: 0 0;
    color: black;
    
  }
  
  #contactSubmit2 {
    width: 100%;
    padding: 8px;
    background: 0 0;
    border: none;
    border: 2px solid black;
    box-sizing: border-box;
    font-family: 'Cairo', sans-serif;
    font-size: 18px;
    color: black;
    font-weight: 800;
    transition: 0.3s color;
  }
  
  #contactSubmit2:hover {
    width: 100%;
    padding: 8px;
    background-color: white;
    border: 2px solid white;
    color: #69c2ff;
    cursor: pointer;
  }
  
  form {
    width: 100%;
    font-family: 'Cairo', sans-serif;
  }
  
  .pu-content-container2 h1 {
    margin-top: 0;
    /* font-family: 'Shrikhand', cursive; */
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
    color: black;
  }
  
  