#about h2 {
  text-align: center;
  font-size: 48px;
  font-family: 'Oswald', sans-serif;
  color: black;
}

#about {
  font-family: 'Cairo', sans-serif;
  margin: auto;
  /* width: 80%; */
}

.aboutFlex {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.aboutContent {
  width: 60%;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 30px;
  /* justify-content: center; */
}

.aboutContent a{
  color: black;
  text-decoration: underline;
}

.aboutPicture {
  width: 40%;
  box-sizing: border-box;
  padding: 10px;
  /* border-right: 2px solid black; */

  background-color: rgba(255, 255, 255, 1);
  border-radius: 1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.aboutPicture:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.aboutPicture img {
  width: 60%;
}

.aboutPicture2 {
  /* background-color: rgba(255, 255, 255, 1);
  border-radius: 1em;
  box-sizing: border-box; */
  /* border: 2px solid #69c2ff; */
  margin: 15px;
  padding: 10px 20px;
  box-sizing: border-box;
  padding: 10px;
  /* position: fixed; */
}

.aboutContent2 {
  box-sizing: border-box;
  padding: 10px;
  /* justify-content: center; */
}

.aboutPicture2 img {
  width: 60%;
}

.aboutPicture a {
  color: black;
  text-decoration: none;
}

.aboutPicture a:hover {
  text-decoration: underline;
}

.mobileAbout a {
  color: black;
  text-decoration: none;
}

.aboutContent2 a{
  text-decoration: underline;
}

.mobileAbout a:hover {
  text-decoration: underline;
}

.mobileAbout {
  display: none;
}
.mobileAbout h2 {
  margin-top: 0;
}

.aboutContent h2{
    margin-top: 0;
}
@media only screen and (max-width: 750px) {
  .mobileAbout {
    display: block;
  }
  .aboutFlex {
    display: none;
  }
  .aboutPicture2 img {
    max-width: 100%;
    height: auto;
    width:300px;
  }
}

@media only screen and (max-width: 450px) {
    .img-fluid {
      max-width: 100%;
      height: auto;
    }
  }
